import Auth0ProviderWithHistory from '@auth/Auth0ProviderWithHistory';

import { AppbarContextProvider } from '@contexts/AppbarContext';
import { Auth0TokenProvider } from '@contexts/Auth0TokenContext';
import { UrlProvider } from '@contexts/UrlQueryParamsContext';

import AppWrapper from './AppWrapper';
import { AuthenticationGuard } from './AuthenticationGuard';

export const Root = () => {
  return (
    <AppbarContextProvider>
      <Auth0ProviderWithHistory>
        <AuthenticationGuard>
          <Auth0TokenProvider>
            <UrlProvider>
              <AppWrapper />
            </UrlProvider>
          </Auth0TokenProvider>
        </AuthenticationGuard>
      </Auth0ProviderWithHistory>
    </AppbarContextProvider>
  );
};

export default Root;
