import type { FC } from 'react';

import { IconProps as IconPropsBase, IconSize } from './types';

export type MaterialIconProps = IconPropsBase & {
  name:
    | 'add'
    | 'analytics'
    | 'apps'
    | 'arrow_drop_down'
    | 'arrow_drop_up'
    | 'arrow_left'
    | 'arrow_right'
    | 'border_all'
    | 'check'
    | 'chevron_right'
    | 'close'
    | 'content_copy'
    | 'delete'
    | 'delete_forever'
    | 'description'
    | 'download'
    | 'drag_indicator'
    | 'folder'
    | 'grade'
    | 'group'
    | 'help'
    | 'help_outlined'
    | 'inbox'
    | 'info'
    | 'keyboard_command_key'
    | 'link'
    | 'logout'
    | 'more_horiz'
    | 'more_vert'
    | 'visibility_off'
    | 'push_pin'
    | 'play_arrow'
    | 'privacy_tip'
    | 'schedule'
    | 'search'
    | 'radio_button_checked'
    | 'send'
    | 'swap_vert'
    | 'terminal'
    | 'radio_button_unchecked'
    | 'upload'
    | 'visibility';
  variant?: 'outlined' | 'round' | 'sharp' | 'two-tone';
};

export const Icon: FC<MaterialIconProps> = ({
  className,
  variant = 'outlined',
  size = 'default',
  name,
}) => {
  const materialIconType = `material-icons${variant ? `-${variant}` : ''}`;

  return (
    <div
      className={`${materialIconType} align-middle ${className ?? ''}`}
      style={{ fontSize: IconSize[size] }}
    >
      {name}
    </div>
  );
};

export default Icon;
