import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Location, useLocation, useNavigate } from 'react-router';

import {
  defaultConfigDataDummy,
  optimizerDefaultUrl,
  OptimizerUrlState,
  PlotConfigDataForUrl,
  StepsState,
} from '@config/optimizerUrl';
import { toast } from '@pxui/components/ui/toast/useToast';

export interface UrlContextState {
  loadQueryParamsFromUrl: (locationState: Location) => OptimizerUrlState;
  params: OptimizerUrlState;
  updateParams: (updates: Partial<OptimizerUrlState>) => void;
}

const UrlContext = createContext<UrlContextState | null>(null);

export const UrlProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const loadQueryParamsFromUrl = (
    locationState: Location,
  ): OptimizerUrlState => {
    const query = new URLSearchParams(locationState.search);
    const params: Partial<OptimizerUrlState> = {};

    query.forEach((value, key) => {
      try {
        params[key as keyof OptimizerUrlState] = JSON.parse(value);
      } catch {
        const typedKey = key as keyof OptimizerUrlState;
        switch (typedKey) {
          case 'includeParetoFront': // boolean
            params[typedKey] = value === 'true';
            break;

          case 'metrics':
          case 'optimizationIds': // string array
          case 'satisfiedConstraints': // string array
            try {
              params[typedKey] = JSON.parse(value) as string[];
            } catch {
              console.error(
                `Malformed JSON for key '${typedKey} in the URL': Expected an array of strings.`,
              );
              params[typedKey] = undefined; // Fallback to an empty array
            }
            if (typedKey === 'optimizationIds') {
              toast({
                description:
                  'The provided optimization ids in the url are invalid, please select new optimizations from the list on Home page.',
                title: 'Invalid url',
                variant: 'error',
              });
            }
            break;

          case 'plotConfigData': // array of PlotConfigData
            try {
              params[typedKey] = JSON.parse(value) as PlotConfigDataForUrl[];
            } catch {
              console.error(
                `Malformed JSON for key '${typedKey} in the URL': Expected an array of PlotConfigData.`,
              );
              params[typedKey] = defaultConfigDataDummy; // Fallback to an empty array
            }
            break;

          case 'steps': // StepsState
            try {
              params[typedKey] = JSON.parse(value) as StepsState;
            } catch {
              console.error(
                `Malformed JSON for key '${typedKey} in the URL': Expected a valid StepsState.`,
              );
              params[typedKey] = undefined; // Fallback to undefined
            }
            break;

          default:
            console.error(`Unsupported url parameter provided: ${typedKey}`);
            break;
        }
      }
    });

    return { ...optimizerDefaultUrl, ...params } as OptimizerUrlState;
  };

  // Initialize state from URL or default values
  const [queryParams, setQueryParams] = useState<OptimizerUrlState>(() =>
    loadQueryParamsFromUrl(location),
  );

  // Set the state updates to URL
  useEffect(() => {
    const query = new URLSearchParams();

    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        query.set(key, JSON.stringify(value));
      }
    });

    navigate({ search: query.toString() }, { replace: true });
  }, [queryParams, navigate]);

  // Update function
  const updateParams = useCallback((updates: Partial<OptimizerUrlState>) => {
    setQueryParams((prev) => ({ ...prev, ...updates }));
  }, []);

  const value = useMemo(
    () => ({ loadQueryParamsFromUrl, params: queryParams, updateParams }),
    [queryParams, updateParams],
  );
  return <UrlContext.Provider value={value}>{children}</UrlContext.Provider>;
};

export const useUrlContext = () => {
  const context = useContext(UrlContext);
  if (!context) {
    throw new Error('useUrlContext must be used within a UrlProvider');
  }
  return context;
};
