import { CandidateSelectionMode } from '@optimizer/schemas/filteredOptimizationsSchema';
import { UncertaintySelectionModeValue } from '@optimizer/states/uncertaintySelectionMode';
import { PlotStaticData, PlotType } from '@optimizer/types/plotStateTypes';
import { v4 as uuidv4 } from 'uuid';

export interface StepsState {
  candidateSelection?: CandidateSelectionMode;
  // max?: number;
  // min?: number;
  selectedRange?: [number, number];
  spacing?: number;
  uncertaintyMode?: UncertaintySelectionModeValue;
}

export interface PlotDynamicDataForUrl {
  highlighted: boolean;
  metricX?: string;
  metricY?: string;
  name: string;
  selectedMetrics?: string[];
  selectedOptimizationId?: string;
  selectedParams?: string[];
}

export interface PlotConfigDataForUrl
  extends PlotStaticData,
    PlotDynamicDataForUrl {}

export interface OptimizerUrlState {
  includeParetoFront?: boolean;
  metrics?: string[];
  optimizationIds?: Array<string>;
  plotConfigData?: Partial<PlotConfigDataForUrl>[];
  satisfiedConstraints?: string[];
  steps?: StepsState;
}

export const defaultConfigDataDummy = [
  {
    id: uuidv4(),
    type: PlotType.LINE,
  },
  {
    id: uuidv4(),
    type: PlotType.SCATTER,
  },
];

export const optimizerDefaultUrl: OptimizerUrlState = {
  includeParetoFront: true,
  metrics: undefined,
  optimizationIds: undefined,
  plotConfigData: defaultConfigDataDummy,
  satisfiedConstraints: undefined,
  steps: {
    candidateSelection: CandidateSelectionMode.MAX,
    // eslint-disable-next-line no-magic-numbers
    selectedRange: [1, 20],
    spacing: 0,
    uncertaintyMode: '0',
  },
};
